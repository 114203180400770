import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { ICourierStatus } from "interfaces/entity-interfaces";

@autoinject()
export class CourierStatus {
    _router: Router;

    courierStatuses: Array<ICourierStatus>;
    isLoadingData: boolean = false;

    constructor(router: Router) {
        this._router = router;
    }

    async activate() {
        await this.getCourierStatuses();
    }
    attached() {}

    async getCourierStatuses() {
        this.isLoadingData = true;
        const returnedData = await fetch(
            "/courierStatuses/GetCourierStatuses",
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    //'Authorization': `Bearer ${this._authenticationService.session.token}`
                },
            }
        );

        this.courierStatuses = await returnedData.json();
        this.isLoadingData = false;

        this.courierStatuses.forEach((status) => {
            status.isEditing = false;
        });
    }

    editCourierStatus(courierStatus) {
        courierStatus.isEditing = true;
    }

    async saveCourierStatus(courierStatus) {
        courierStatus.isEditing = false;

        const returnedData = await fetch(
            "/courierStatuses/UpdateCourierStatus",
            {
                method: "POST",
                body: JSON.stringify(courierStatus),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        );

        console.log(returnedData);

        await this.getCourierStatuses();
    }

    toggleGoOutOnRoute(courierStatus) {
        courierStatus.goingOutOnRoute = !courierStatus.goingOutOnRoute;
    }

    async goOutOnRoute(courierStatus) {
        courierStatus.goingOutOnRoute = false;
        courierStatus.courierStatus = "Out On Route";

        await this.saveCourierStatus(courierStatus);
    }

    printRouteSheet(courierStatus) {
        this._router.navigateToRoute("print-courier-sheet", {
            routeShortId: courierStatus.routeShortId,
        });
    }

    printAllCourierRouteSheets() {
        this._router.navigateToRoute("print-courier-sheet-all");
    }
}
