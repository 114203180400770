import { DialogService } from "aurelia-dialog";
import { ClinicModal } from "./../modals/clinics-modals/clinic-modal";
import { DataService } from "./../services/data-service";
import { autoinject, observable } from "aurelia-framework";
import { IClient, IClinic } from "interfaces/entity-interfaces";
import { UrinalysisModal } from "modals/requisition-modals/urinalysis-modal";
import { HistoModal } from "modals/requisition-modals/histo-modal";
import { IntegrationModal } from "modals/integration-modal";

@autoinject
export class Clinics {
    private _dataService: DataService;
    private _dialogService: DialogService;

    displayedClinics: IClient[] = [];
    clinics: IClient[] = [];

    isLoadingClinics: boolean = false;

    @observable({ changeHandler: "searchUpdated" })
    clinicSearchTerm: string = "";

    constructor(dataService: DataService, dialogService: DialogService) {
        this._dataService = dataService;
        this._dialogService = dialogService;
    }

    async activate() {
        this.getClinics();
    }
    attached() {}

    async getClinics() {
        this.isLoadingClinics = true;
        this.clinics = await this._dataService.getClinics(null);
        this.isLoadingClinics = false;
        this.displayedClinics = this.clinics;
    }

    searchUpdated() {
        if (this.clinicSearchTerm == "") {
            this.displayedClinics = this.clinics;
            return;
        }

        const clinicSearchTermLower = this.clinicSearchTerm.toLowerCase();

        const nameSearchClinics = this.clinics.filter((clinic) =>
            clinic.name.toLowerCase().includes(clinicSearchTermLower)
        );
        const emailSearchClinics = this.clinics.filter((clinic) =>
            clinic.email.some((email) =>
                email.toLowerCase().includes(clinicSearchTermLower)
            )
        );
        const numberSearchClinics = this.clinics.filter((clinic) =>
            clinic.clinicId.toString().includes(clinicSearchTermLower)
        );

        this.displayedClinics = Array.from(
            new Set([
                ...nameSearchClinics,
                ...emailSearchClinics,
                ...numberSearchClinics,
            ])
        );
    }

    openClinicModal(clinic: IClient) {
        this._dialogService
            .open({
                viewModel: ClinicModal,
                model: { clinic: clinic },
                lock: false,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    // this.fetchCourierRouteSheets();
                } else {
                    // this.fetchCourierRouteSheets();
                }
                console.log(response.output);
            });
    }
    hasIntegrationPartner(clinic: IClinic) {
        return clinic.clients.some(
            (client) => client.integrationPartner != null
        );
    }

    openUrineModal() {
        this._dialogService
            .open({
                viewModel: UrinalysisModal,
                model: {},
                lock: true,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    // this.fetchCourierRouteSheets();
                } else {
                    // this.fetchCourierRouteSheets();
                }
                console.log(response.output);
            });
    }

    openSkinModal() {
        this._dialogService
            .open({
                viewModel: HistoModal,
                model: {},
                lock: false,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    // this.fetchCourierRouteSheets();
                } else {
                    // this.fetchCourierRouteSheets();
                }
                console.log(response.output);
            });
    }

    openIntegrationModal(clinic: IClient) {
        this._dialogService
            .open({
                viewModel: IntegrationModal,
                model: clinic,
                lock: false,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    // this.fetchCourierRouteSheets();
                } else {
                    // this.fetchCourierRouteSheets();
                }
                console.log(response.output);
            });
    }
}
