export class ClientCourierSettings {

	clients: Array<any> = [];

	weekdayMorningRoutes = [
		"A",
		"B",
		"C",
		"D",
		"E",
		"F"
	];

	weekdayAfternoonRoutes = [
		"G",
		"H"
	];

	weekdayEveningRoutes = [
		"1",
		"2",
		"3",
		"4",
		"5",
		"6"
	];

	saturdayMorningRoutes = [
		"A"
	];

	saturdayAfternoonRoutes = [
		"B",
		"C",
		"D",
		"E",
		"F",
		"G"
	];

	sundayRoutes = [
		"A",
		"B"
	];

	isEditing = false;

	async activate() {
		const returnedData = fetch(`/clients/getClients`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				//'Authorization': `Bearer ${this._authenticationService.session.token}`

			}
		}).then(response => {
			response.json().then(jsonData => {

				//this.clients = jsonData

				var currentClientId = 0;

				this.clients = jsonData.filter((client) => {
					if (currentClientId == client.clientId) {
						return false;
					}

					currentClientId = client.clientId;

					return true
				})
			})
		});
	}

	toggleAutomaticPickup(client) {
		if (!this.isEditing)
			return false;

		client.hasAutomaticCourierPickup = !client.hasAutomaticCourierPickup;
	}
	toggleMorningAutomaticPickup(client) {
		if (!this.isEditing)
			return false;

		client.morningAutomaticPickUp = !client.morningAutomaticPickUp;
	}
	toggleAfternoonAutomaticPickup(client) {
		if (!this.isEditing)
			return false;

		client.afternoonAutomaticPickUp = !client.afternoonAutomaticPickUp;
	}
	toggleEveningAutomaticPickup(client) {
		if (!this.isEditing)
			return false;

		client.eveningAutomaticPickUp = !client.eveningAutomaticPickUp;
	}
	toggleSaturdayMorningAutomaticPickup(client) {
		if (!this.isEditing)
			return false;

		client.saturdayMorningAutomaticPickUp = !client.saturdayMorningAutomaticPickUp;
	}
	toggleSaturdayAfternoonAutomaticPickup(client) {
		if (!this.isEditing)
			return false;

		client.saturdayAfternoonAutomaticPickUp = !client.saturdayAfternoonAutomaticPickUp;
	}
	toggleSundayAutomaticPickup(client) {
		if (!this.isEditing)
			return false;

		client.sundayAutomaticPickUp = !client.sundayAutomaticPickUp;
	}

	turnOnEditing() {
		this.isEditing = true;
	}

	save() {
		this.isEditing = false;

		const returnedData = fetch(`/clients/getClients`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				//'Authorization': `Bearer ${this._authenticationService.session.token}`

			},
			body: JSON.stringify(this.clients)
		}).then(response => {
			response.json().then(jsonData => {

				//this.clients = jsonData

				var currentClientId = 0;

				this.clients = jsonData.filter((client) => {
					if (currentClientId == client.clientId) {
						return false;
					}

					currentClientId = client.clientId;

					return true
				})
			})
		});
	}

	print() {
		window.print();
	}
}