import { autoinject } from "aurelia-framework";
import {
    ICourierRouteSheet,
    ICourierRouteSheetRecord,
} from "interfaces/entity-interfaces";
import FlatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import * as moment from "moment";
import { computedFrom } from "aurelia-binding";
import Swal from "sweetalert2";
import { DialogService } from "aurelia-dialog";
import { AddCourierRouteSheetRecordModal } from "modals/add-courier-route-sheet-record-modal";

@autoinject()
export class CourierDashboard {
    courierRouteSheets: Array<ICourierRouteSheet>;

    flatPickerObject: FlatPickr.Instance;

    currentDashboardDate: Date;

    _dialogService: DialogService;

    clients = [];

    constructor(dialogService: DialogService) {
        this.currentDashboardDate = moment().toDate();
        this._dialogService = dialogService;
    }

    async activate() {
        this.fetchCourierRouteSheets();

        const returnedData = await fetch(`/clients/getClients`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                //'Authorization': `Bearer ${this._authenticationService.session.token}`
            },
        });

        this.clients = await returnedData.json();

        console.log(this.clients);

        window.setInterval(() => {
            this.fetchCourierRouteSheets();
        }, 10000);
    }
    attached() {
        const datepickerElement = document.getElementById(
            "courier-dashboard-datepicker"
        );

        this.flatPickerObject = FlatPickr(datepickerElement, {
            altInput: true,
            defaultDate: this.currentDashboardDate,
            altFormat: "F j, Y",
            maxDate: "today",
            // inline: true,
            onChange: (newDate) => {
                this.currentDashboardDate = newDate[0];
                this.fetchCourierRouteSheets();
            },
        });
    }

    toggleDatePicker() {
        this.flatPickerObject.toggle();
    }

    @computedFrom("currentDashboardDate")
    get formattedCurrentDashboardDate(): string {
        return moment(this.currentDashboardDate).format("MMMM D, YYYY");
    }

    async fetchCourierRouteSheets() {
        const returnedData = await fetch(
            `/courierRouteSheets/GetCourierRoutesSheets?date=${moment(
                this.currentDashboardDate
            ).format("YYYYMMDDHHmmss")}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    //'Authorization': `Bearer ${this._authenticationService.session.token}`
                },
            }
        );

        this.courierRouteSheets = await returnedData.json();

        console.log(this.courierRouteSheets);
    }
    async fetchClients() {
        const returnedData = await fetch(`/clients/getClients`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                //'Authorization': `Bearer ${this._authenticationService.session.token}`
            },
        });

        this.courierRouteSheets = await returnedData.json();

        console.log(this.courierRouteSheets);
    }

    formatOrderTime(courierRouteSheetRecord) {
        if (courierRouteSheetRecord.confirmationCode == "AUTOMATIC - PENDING")
            return "";

        return moment(courierRouteSheetRecord.orderDateTime).format("HH:mm");
    }
    formatConfirmationTime(courierRouteSheetRecord) {
        if (!courierRouteSheetRecord.courierConfirmedReceipt) return "";

        return moment(
            courierRouteSheetRecord.courierConfirmationDateTime
        ).format("HH:mm");
    }

    async postReadback(courierSheet: ICourierRouteSheet) {
        const returnedData = await fetch(
            `/courierReadback?routeShortId=${courierSheet.routeShortId}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    //'Authorization': `Bearer ${this._authenticationService.session.token}`
                },
            }
        );

        this.fetchCourierRouteSheets();
    }
    acceptCourierRecord(courierRouteSheetRecord: ICourierRouteSheetRecord) {
        Swal.fire({
            icon: "question",
            title: "Confirm courier pick-up approval?",
            text: "Please confirm you would like to approve this clinic",
            showDenyButton: true,
            confirmButtonText: `Approve`,
            denyButtonText: `Nevermind`,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const returnedData = await fetch(
                    `/ApproveCourierRouteSheetRecord?clientId=${courierRouteSheetRecord.clientId}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            //'Authorization': `Bearer ${this._authenticationService.session.token}`
                        },
                    }
                );

                Swal.fire({
                    icon: "success",
                    title: "Clinic Pick-Up Approved!",
                    text: " has been approved from the courier sheet!",
                });

                this.fetchCourierRouteSheets();
            } else if (result.isDenied) {
            }
        });
    }
    removeCourierRecord(
        courierRouteSheetRecord: ICourierRouteSheetRecord,
        courierRouteSheet: ICourierRouteSheet
    ) {
        Swal.fire({
            icon: "warning",
            title: "Confirm courier pick-up removal?",
            text: "Please confirm you would like to remove this clinic from the courier route sheet",
            showDenyButton: true,
            confirmButtonText: `Remove`,
            denyButtonText: `Nevermind`,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const returnedData = await fetch(
                    `/RemoveCourierRouteSheetRecord?clientId=${courierRouteSheetRecord.clientId}&shortRouteId=${courierRouteSheet.routeShortId}&confirmationCode=${courierRouteSheetRecord.confirmationCode}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            //'Authorization': `Bearer ${this._authenticationService.session.token}`
                        },
                    }
                );

                Swal.fire({
                    icon: "success",
                    title: "Courier Route Sheet Record Removed!",
                });

                this.fetchCourierRouteSheets();
            } else if (result.isDenied) {
            }
        });
    }

    openNewCourierRecordModal() {
        console.log(this._dialogService);

        this._dialogService
            .open({
                viewModel: AddCourierRouteSheetRecordModal,
                model: {
                    courierRoutes: this.courierRouteSheets,
                    clients: this.clients,
                },
                lock: false,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.fetchCourierRouteSheets();
                } else {
                    this.fetchCourierRouteSheets();
                }
                console.log(response.output);
            });
    }

    async openCourierRecordNotesModal(
        courierRouteSheetRecord,
        courierRouteSheet
    ) {
        var existingNotes = "";

        if (courierRouteSheet.routeTimeOfDay == "Morning")
            existingNotes = courierRouteSheetRecord.morningNotes;

        if (courierRouteSheet.routeTimeOfDay == "Afternoon")
            existingNotes = courierRouteSheetRecord.afternoonNotes;

        if (courierRouteSheet.routeTimeOfDay == "Evening")
            existingNotes = courierRouteSheetRecord.eveningNotes;

        if (existingNotes == null) existingNotes = "";

        const { value: newNotes } = await Swal.fire({
            input: "textarea",
            inputLabel: "Courier Notes",
            inputValue: existingNotes,
            inputPlaceholder: "Enter message notes here",
            inputAttributes: {
                "aria-label": "Type your message here",
            },
            showCancelButton: true,
        });

        if (newNotes) {
            console.log(newNotes);

            if (courierRouteSheet.routeTimeOfDay == "Morning")
                courierRouteSheetRecord.morningNotes = newNotes;

            if (courierRouteSheet.routeTimeOfDay == "Afternoon")
                courierRouteSheetRecord.afternoonNotes = newNotes;

            if (courierRouteSheet.routeTimeOfDay == "Evening")
                courierRouteSheetRecord.eveningNotes = newNotes;

            const returnedData = await fetch(
                `/courierRouteSheets/UpdateCourierRecordNotes`,
                {
                    method: "POST",
                    body: JSON.stringify(courierRouteSheetRecord),
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        //'Authorization': `Bearer ${this._authenticationService.session.token}`
                    },
                }
            );

            this.fetchCourierRouteSheets();
        }
    }

    doesNoteExist(courierRouteSheetRecord, courierRouteSheet) {
        if (courierRouteSheet.routeTimeOfDay == "Morning")
            return (
                courierRouteSheetRecord.morningNotes != null &&
                courierRouteSheetRecord.morningNotes != ""
            );

        if (courierRouteSheet.routeTimeOfDay == "Afternoon")
            return (
                courierRouteSheetRecord.afternoonNotes != null &&
                courierRouteSheetRecord.afternoonNotes != ""
            );

        if (courierRouteSheet.routeTimeOfDay == "Evening")
            return (
                courierRouteSheetRecord.eveningNotes != null &&
                courierRouteSheetRecord.eveningNotes != ""
            );

        return false;
    }
}
